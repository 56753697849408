@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-VariableFont_wght.ttf");
  font-display: swap;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Bold.ttf");
  font-display: swap;
  font-weight: bold;
}

@font-face {
  font-family: "Inter";
  src: url("/fonts/Inter/Inter-Medium.ttf");
  font-display: swap;
  font-weight: medium;
}

* {
  font-family: "Poppins", sans-serif, Arial;
}

html,
body {
  background-color: black;

  /* Allow sticky header to work while still hiding X scrollbar */
  /*overflow-x: clip;*/
}

* {
  box-sizing: border-box;
}

.asset-zoom-fullscreen-component {
  display: flex;
  flex: 1;
}

.file-manager-selection-area {
  background-color: #42f2f22d;
  border: 1px solid #42f2f2;
}

button[disabled] {
  pointer-events: none;
  cursor: not-allowed;
}

.checkerboard {
  background-position: 0px 0px, 10px 10px;
  background-size: 20px 20px;
  background-image: linear-gradient(
      45deg,
      #eee 25%,
      transparent 25%,
      transparent 75%,
      #eee 75%,
      #eee 100%
    ),
    linear-gradient(45deg, #eee 25%, white 25%, white 75%, #eee 75%, #eee 100%);
}
